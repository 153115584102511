import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Masonry from "react-masonry-component";
import DevIcon from "devicon-react-svg";



import {
  Title,
  Section,
  Box,
  Text,
  ButtonOutline,
} from "../../components/Core";
import RotateImg from "../../components/RotateImg";
import { devWorks2 } from "../../data";

import { useWindowSize } from "../../hooks";
import { breakpoints } from "../../utils";

const WorkCard = styled(Box)``;
const WorkText = styled(Box)``;

const Grid = (props) => {
  const size = useWindowSize();

  const masonryOptions = {
    transitionDuration: 1000,
  };

  return size.width < breakpoints.lg ? (
    <Masonry
      options={masonryOptions}
      className={"masonry-grid row"}
      {...props}
    />
  ) : (
    <Row {...props} />
  );
};
const TextBox = styled(Box)`
  position: absolute;
  overflow: hidden;
  text-align: center;
  bottom: 170px;
  left: 20px;
  right: 20px;
  opacity: 1;
  border-radius: 8px;
  z-index: 1;
  padding: 1.25rem 1.875rem;
  transition: 0.4s;

  ${WorkCard}:hover & {
    bottom: 170px;
    opacity: 0;
  }
`;
const StyledIcon = styled(DevIcon)`
  fill: #5cdddf;
  width: 60px;
`;

const Works = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(devWorks2);
  }, []);

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative pt-0">
        <Container>
          <Grid>
            {items.map((item, index) => (
              <Col md="6" key={index} className="filtr-item">
                <WorkCard className="position-relative" mb="30px">
                  <RotateImg link={item.link} imgSrc={item.thumbnail} />
                  <TextBox className="text-center">
                    {item.stacks.map((icons, i) => (
                      <StyledIcon icon={icons} />
                    ))}
                  </TextBox>
                  <Box pt="2.125rem">
                    <WorkText className="overflow-hidden text-center">
                      <Text variant="tag" mb="0.5rem">
                        {item.categories[0]}
                      </Text>
                      <Title variant="cardLg">
                        <Link to={item.link}>{item.brand} </Link>
                      </Title>
                      <Text variant="tag" mb="0.5rem">
                        {item.title}
                      </Text>
                    </WorkText>
                  </Box>
                </WorkCard>
              </Col>
            ))}
          </Grid>
          <Box pt="3rem" className="text-center">
            <Link
              to="https://github.com/imarcdaniel"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              <ButtonOutline>see more works</ButtonOutline>
            </Link>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default Works;
