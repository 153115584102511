import imgT1 from "../assets/image/jpg/dev/porto/resumates.png";
import imgT2 from "../assets/image/jpg/dev/porto/parlons.png";
import imgT3 from "../assets/image/jpg/dev/regular/portfolio-3.jpg";
import imgT4 from "../assets/image/jpg/dev/regular/portfolio-3.png";
import imgT5 from "../assets/image/jpg/dev/regular/portfolio-5.jpg";
import imgT6 from "../assets/image/jpg/dev/regular/portfolio-6.jpg";
import imgT7 from "../assets/image/jpg/dev/regular/portfolio-7.jpg";
import imgT8 from "../assets/image/jpg/dev/regular/portfolio-8.jpg";

export const devWorks2 = [
         {
           brand: `Resumates App`,
           slug: `resumates-app`,
           title: `A database of resume samples from working professionals.`,
           categories: [`web`],
           thumbnail: imgT1,
           link: "https://resumatees.herokuapp.com/",
           stacks: [`react`, `css3`, `bootstrap`, `aws`],
         },
         {
           brand: `PARLONS`,
           slug: `parlons-app`,
           title: `an instant messaging web app to discuss local topics`,
           categories: [`app`, `web`],
           thumbnail: imgT2,
           link: "https://github.com/imarcdaniel/PROJECT-BASH-V1-N22-CHAT",
           stacks: [`python`, `django`, `postgresql`, `google-cloud-platform`],
         },
         {
           brand: `HOOPED`,
           slug: `hooped`,
           title: ` employee carpooling manager for corporations.`,
           categories: [`web`],
           thumbnail: imgT3,
           link: "https://hooped.herokuapp.com/",
           stacks: [`javascript`, `mongodb`, `nodejs`, `css3`],
         },
         {
           brand: `Mancala`,
           slug: `mancala`,
           title: `An interactive 2 player game.`,
           categories: [`app`],
           thumbnail: imgT4,
           link: "https://github.com/imarcdaniel/PROJECT-JAS-V52-S15-MACA",
           stacks: [`css3`, `javascript`],
         },
      
       ];
